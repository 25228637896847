<template>
  <v-row>
    <v-col cols="12">
        <customer-card> </customer-card> 
    </v-col>
  </v-row>
</template>
<script>
import CustomerCard from "@/components/customer/CustomerCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Customer",
  },

  components: {
    CustomerCard,
  },

  data() {
    return {
    };
  },
};
</script>

