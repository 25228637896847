<template>
  <div>
    <base-card :loading="cardLoading">
      <div v-if="customerList == '' && !cardLoading">
        <v-card-text class="text-center">
          <div class="pa-16">
            <v-btn
              class="mx-2"
              fab
              x-large
              disabled
              depressed
              style="font-size: 399px !important"
            >
              <v-icon x-large> mdi-account-details </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="my-2">
              <span class="text-subtitle-1">No Customer Found</span>
            </div>

            <v-spacer></v-spacer>
            <!-- <v-btn dark color="primary" @click="(title = 'Add'), openDialog()">
              <v-icon>mdi-plus</v-icon>
              Add Customer
            </v-btn> -->
            <!-- <v-btn outlined @click="dialogImport = true" class="ma-2"
              >Import</v-btn
            > -->
          </div>
        </v-card-text>
      </div>
      <div v-else>
        <!-- <v-card-title>
          <div class="d-flex justify-space-between flex-wrap"> -->
        <!-- <v-btn
              class="ma-2"
              small
              dark
              color="primary"
              @click="(title = 'Add'), openDialog()"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Customer
            </v-btn> -->
        <!-- <div> -->
        <!-- <v-btn outlined small @click="dialogImport = true" class="ma-2"
                >Import</v-btn
              > -->

        <!-- <v-btn
                class="ma-2"
                small
                dark
                color="danger"
                v-if="selected != ''"
                @click="dialogDelete = true"
              >
                <v-icon small>mdi-delete</v-icon>
                delete
              </v-btn> -->
        <!-- </div>
          </div>
        </v-card-title> -->
        <v-card-title>
          <span>Customer</span>
          <v-spacer></v-spacer>
          <v-btn outlined small class="ma-1" @click="exportCustomer"
            >Export</v-btn
          >
        </v-card-title>
        <v-col cols="12"
          ><v-text-field
            class="mt-n5 mx-1"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field
        ></v-col>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="customerList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          item-key="customer_id"
          hide-default-footer
          @click:row="handleClick"
        >
          <template v-slot:item.totalSpend="{ item }">
            <div v-if="item.totalSpend == null">0.00</div>
            <div v-else>{{ parseFloat(item.totalSpend).toFixed(2) }}</div>
          </template>
          <template v-slot:item.totalVisit="{ item }">
            <div v-if="item.totalVisit == null">0</div>
            <div v-else>{{ item.totalVisit }}</div>
          </template>
          <template v-slot:item.lastVisit="{ item }">
            <div v-if="item.lastVisit == null">-</div>
            <div v-else>{{ changeFormat(item.lastVisit) }}</div>
          </template>
          <template v-slot:item.firstVisit="{ item }">
            <div v-if="item.firstVisit == null">-</div>
            <div v-else>{{ changeFormat(item.firstVisit) }}</div>
          </template>
          <template v-slot:item.phone="{ item }">
            <div v-if="item.phone == '' && item.email == ''">-</div>
            <div v-else-if="item.phone == ''">{{ item.email }}</div>
            <div v-else-if="item.email == ''">{{ item.country_code+item.phone }}</div>
            <div v-else>{{ item.email }}<br />{{ item.country_code+item.phone }}</div>
          </template>
          <template v-slot:item.address="{ item }">
            <div v-if="item.address == ''">-</div>
            <div v-else class="text-truncate" style="max-width: 160px">
              {{ item.address }}
            </div>
          </template>
        </v-data-table>
        <div class="text-center py-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </base-card>

    <v-dialog v-model="dialogImport" max-width="600px">
      <v-card>
        <v-card-title
          ><span class="text-h6 font-weight-black"
            >Import Customer</span
          ></v-card-title
        >
        <v-card-text>
          <a @click="downloadTemplate">Download template file</a> and fill in
          with the customers information.

          <VueFileAgent
            ref="vueFileAgent"
            :deletable="true"
            :maxSize="'10MB'"
            :maxFiles="1"
            v-model="fileArray"
            :accept="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            @change="onChange($event)"
            @beforedelete="onBeforeDelete($event)"
          ></VueFileAgent>

          {{ fileRecords }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            @click="(dialogImport = false), onBeforeDelete()"
            text
            color="red lighten-2"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="uploadCustomer" text> Upload </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} Customer</span>
          </div>
          <v-text-field
            prepend-icon="mdi-account"
            label="Customer Name"
            v-model="cName"
            :error-messages="cNameErrors"
            required
            @input="$v.cName.$touch()"
            @blur="$v.cName.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-phone"
            label="Phone"
            v-model="cPhone"
            :error-messages="cPhoneErrors"
            required
            type="number"
            @input="$v.cPhone.$touch()"
            @blur="$v.cPhone.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-email"
            label="Email"
            v-model="cEmail"
            :error-messages="cEmailErrors"
            required
            @input="$v.cEmail.$touch()"
            @blur="$v.cEmail.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-map-marker"
            label="Address"
            v-model="cAddress"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn> -->
          <v-spacer></v-spacer>
          <!-- <v-btn color="primary" @click="dialogAction()" text> save </v-btn> -->
        </v-card-actions>
      </base-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteCustomer" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  minValue,
  email,
} from "vuelidate/lib/validators";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  mixins: [validationMixin],
  validations: {
    cName: { required },
    cPhone: {
      minLength: minLength(10),
      maxLength: maxLength(11),
      minValue: minValue(0),
      exist(value) {
        if (value === "") return true;
        if (this.title == "Add") {
          return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append("checkRepeatNumber", "done");
            params.append("phone", this.cPhone);
            params.append("company_id", this.companyID);

            axios({
              method: "post",
              url: this.domain + "/customer/index.php",
              data: params,
            })
              .then((response) => {
                const email = response.data.status;
                resolve(email == 2);
              })
              .catch(() => reject(false));
          });
        } else {
          return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append("checkRepeatNumberForEdit", "done");
            params.append("phone", this.cPhone);
            params.append("company_id", this.companyID);
            params.append("customer_id", this.selectCustomer);

            axios({
              method: "post",
              url: this.domain + "/customer/index.php",
              data: params,
            })
              .then((response) => {
                const email = response.data.status;
                resolve(email == 2);
              })
              .catch(() => reject(false));
          });
        }
      },
    },
    cEmail: {
      email,
    },
  },

  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      snackbar: false,
      text: "",
      snackbarColor: "",
      timeout: 1900,
      companyID: "",
      branchList: "",
      headers: [
        {
          text: "Customer",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Contacts", value: "phone", sortable: false },
        { text: "Address", value: "address", sortable: false },
        { text: "First Visit", value: "firstVisit", sortable: false },
        { text: "Last Visit", value: "lastVisit", sortable: false },
        { text: "Total Visit", value: "totalVisit", sortable: false },
        { text: "Total Spend", value: "totalSpend", sortable: false },
      ],
      customerList: [],
      dialog: false,
      title: "",
      cName: "",
      cPhone: "",
      cEmail: "",
      cAddress: "",
      selectCustomer: "",
      cardLoading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialogDelete: false,
      search: "",
      selected: [],
      dialogImport: false,
      // ------
      fileRecords: "",

      //   testing
      fileArray: [],
    };
  },

  computed: {
    cNameErrors() {
      const errors = [];
      if (!this.$v.cName.$dirty) return errors;
      !this.$v.cName.required && errors.push("Customer name is required");
      return errors;
    },
    cPhoneErrors() {
      const errors = [];
      if (!this.$v.cPhone.$dirty) return errors;
      !this.$v.cPhone.minValue &&
        errors.push("Phone cannot be negative number");
      !this.$v.cPhone.minLength && errors.push("Phone must at least 10 number");
      !this.$v.cPhone.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.cPhone.exist && errors.push("Phone already exists");

      return errors;
    },
    cEmailErrors() {
      const errors = [];
      if (!this.$v.cEmail.$dirty) return errors;
      !this.$v.cEmail.email && errors.push("Please enter an valid email");
      return errors;
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllCustomer();
    this.getCompanyBranch();
  },

  methods: {
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllCustomer() {
      const params = new URLSearchParams();
      params.append("getAllCustomer", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.customerList = response.data.customer;
            this.cardLoading = false;
          } else {
            console.log("no list get");
            this.customerList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeFormat(date) {
      var moment = require("moment");
      return moment(date).format("DDMMMYYYY, h:mm a");
    },

    openDialog() {
      if (this.title == "Add") {
        this.$v.$reset();
        this.cName = "";
        this.cPhone = "";
        this.cEmail = "";
        this.cAddress = "";
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },

    handleClick(item) {
      this.cName = item.name;
      this.cPhone = item.phone;
      this.cEmail = item.email;
      this.cAddress = item.address;
      this.selectCustomer = item.customer_id;
      this.title = "Edit";
      this.openDialog();
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteCustomer() {
      if (this.selected != "") {
        const array = [];
        for (let i = 0; i < this.selected.length; i++) {
          array.push(this.selected[i].customer_id);
        }
        const params = new URLSearchParams();
        params.append("deleteMultiCustomer", "done");
        params.append("array_customer_id", JSON.stringify(array));
        axios({
          method: "post",
          url: this.domain + "/customer/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.selected = [];
              this.dialogDelete = false;
              this.getAllCustomer();
              for (let i = 0; i < this.branchList.length; i++) {
                this.sendNotication(this.branchList[i].branch_id);
              }
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("deleteCustomer", "done");
        params.append("customer_id", this.selectCustomer);
        axios({
          method: "post",
          url: this.domain + "/customer/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.dialogDelete = false;
              this.getAllCustomer();
              for (let i = 0; i < this.branchList.length; i++) {
                this.sendNotication(this.branchList[i].branch_id);
              }
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    downloadTemplate() {
      import("@/data/Export2Excel").then((excel) => {
        const tHeader = ["name", "phone", "email", "address"];
        const data = "";
        excel.export_json_to_excel({
          header: tHeader, //Header Required
          data, //Specific data Required
          filename: "customer-form", //Optional
          autoWidth: true, //Optional
          bookType: "xlsx", //Optional
        });
      });
    },

    onChange() {
      //   var XLSX = require("xlsx");
      this.files = event.target.files ? event.target.files[0] : null;
      if (this.files) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.fileRecords = data;
        };

        reader.readAsBinaryString(this.files);
      }
    },

    onBeforeDelete(fileArray) {
      this.$refs.vueFileAgent.deleteFileRecord(fileArray);
      this.fileRecords = "";
    },

    exportCustomer() {
      var moment = require("moment");
      var day = moment().format("DD-MM-YYYY")
      import("@/data/Export2Excel").then((excel) => {
        const tHeader = [
          "Name",
          "Country_code",
          "Phone",
          "Email",
          "Address",
          "FirstVisit",
          "LastVisit",
          "TotalVisit",
          "TotalSpend",
        ];
        const filterVal = [
          "name",
          "country_code",
          "phone",
          "email",
          "address",
          "firstVisit",
          "lastVisit",
          "totalVisit",
          "totalSpend",
        ];
        const list = this.customerList;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader, //Header Required
          data, //Specific data Required
          filename: "Customer Record"+day, //Optional
          autoWidth: true, //Optional
          bookType: "xlsx", //Optional
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    uploadCustomer() {
      const params = new URLSearchParams();
      params.append("uploadCustomer", "done");
      params.append("customer_array", JSON.stringify(this.fileRecords));
      params.append("company_id", this.companyID);
      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialogImport = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.onBeforeDelete();
            this.getAllCustomer();
            for (let i = 0; i < this.branchList.length; i++) {
              this.sendNotication(this.branchList[i].branch_id);
            }
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addCustomer", "done");
          params.append("company_id", this.companyID);
          params.append("name", this.cName);
          params.append("phone", this.cPhone);
          params.append("email", this.cEmail);
          params.append("address", this.cAddress);
          axios({
            method: "post",
            url: this.domain + "/customer/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllCustomer();
                for (let i = 0; i < this.branchList.length; i++) {
                  this.sendNotication(this.branchList[i].branch_id);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editCustomer", "done");
          params.append("customer_id", this.selectCustomer);
          params.append("name", this.cName);
          params.append("phone", this.cPhone);
          params.append("email", this.cEmail);
          params.append("address", this.cAddress);
          axios({
            method: "post",
            url: this.domain + "/customer/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllCustomer();
                for (let i = 0; i < this.branchList.length; i++) {
                  this.sendNotication(this.branchList[i].branch_id);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>